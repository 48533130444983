/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { NoStackProvider } from '@nostack/no-stack';

import GlobalStyles from './src/ui-components/global';

import client from './src/client';
import { PLATFORM_ID } from './src/config';

export const wrapRootElement = ({ element }) => (
  <NoStackProvider client={client} platformId={PLATFORM_ID}>
    <GlobalStyles />
    {element}
  </NoStackProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
