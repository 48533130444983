import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import cssVariables from './variables';

export default createGlobalStyle`
  ${reset}

  html, body {
    font-family: ${cssVariables.bodyFont};
    color: ${cssVariables.textColor};
  }
[data-testid="layout"] [href="https://github.com/gatsbyjs/gatsby-starter-blog"],
[data-testid="layout"]  button,
[data-testid="layout"]  [rel="noopener noreferrer"]
{
  display: none !important;
}
  h1,
  h2,
  h3 {
    font-family: ${cssVariables.headerFont};
  }

  button::-moz-focus-inner {
    border: 0;
  }

  h1,
  h2,
  h3,
  h4,
  ul,
  li,
  p,
  form {
    margin: 0;
    padding: 0;
    color: ${cssVariables.textColor};
  }

  table {
    width: 100%;
  }

  button {
    cursor: pointer;
  }

  input {
    &:focus {
      outline: none;
    }
  }

  strong {
    font-weight: 600;
  }

  img {
    border: none;
    max-width: none;
  }

  input,
  textarea,
  label {
    font-family: ${cssVariables.textFont};

    color: ${cssVariables.contentTextColor};
    font-size: 18px;
    line-height: 24px;
  }

  input,
  textarea {
    border: solid 1px #707070;
    background: #ddf4d9;

    padding: 19px 16px 15px;

    &:focus {
      background: ${cssVariables.colors.white};
    }
  }

  @media only screen and (max-width: 1560px) {
    html {
      overflow-x: hidden;
    }
  }

  @media only screen and (max-width: 768px) {
    body {
      position: relative;
    }

    div[role="group"][tabindex] {
      position: relative;
    }
  }
`;
