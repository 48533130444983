const fontFamilies = {
  comfortaa: "'Comfortaa', cursive",
  openSans: "'Open Sans', sans-serif",
};

const colors = {
  black: '#202020',
  white: '#ffffff',
  faintGreen: '#eeffeb',
  green: '#18b202',
  freeSpeechGreen: '#21d407',
  teaGreen: '#caffc2',
  darkGreen: '#0b5100',
  darkGray: '#4C5C4C',
  lightGray: '#6e6e6e',
  silver: '#bbb',
  whisper: '#eee',
};

export default {
  fontFamilies,
  colors,

  // fonts
  bodyFont: fontFamilies.openSans,
  headerFont: fontFamilies.comfortaa,
  textFont: fontFamilies.openSans,

  // text palette
  textColor: colors.black,
  textColorSubtitle: colors.darkGray,
  quotationColor: colors.darkGray,
  footerTextColor: colors.lightGray,
  contentHeaderColor: colors.black,
  contentTextColor: colors.darkGray,
  placeholderColor: colors.silver,
  invertTextColor: colors.white,

  // backgrounds
  mobileFeaturesBG: colors.whisper,
  gradientStart: colors.green,
  gradientEnd: colors.black,
  buttonBG: colors.green,
  buttonBGHover: colors.freeSpeechGreen,
  buttonBGInvert: colors.white,
  buttonBGInvertHover: colors.teaGreen,
};
